import PageError from "../components/Pages/PageError"
import Layout from "../components/Layout"
import useTranslation from "next-translate/useTranslation"
import appConfig from "../app-config"
import getStaticSessionProps from "@hornet-web-react/core/middleware/next-props/get-static-session"

export default function Custom404() {
  const { t } = useTranslation()

  return (
    <Layout title={t("hornet:error_page.page_not_found_message")}>
      <PageError
        message={t("hornet:error_page.page_not_found_message")}
        statusCode={404}
      />
    </Layout>
  )
}

export async function getStaticProps(context) {
  return getStaticSessionProps(appConfig, context, {
    environment: {
      isInApp: false,
      inAppVersion: null,
    },
  })
}
